import React from "react";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import Layout from "../../components/layout";
interface props {}
const CaseStudy: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Case Study"
      titleText2=""
      slogan="We provide a case study below giving an example of the programme work we do."
    >
      <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col"
        header="Delivering group coaching as part of our leadership programme work."
      />

      <p style={{marginTop: "35px"}}>
        <strong>Purpose:</strong> To identify and work on individual and
        organisational challenges within a group creating action plans and a
        network to share experience, support and challenge.
      </p>

      <p style={{margin: "35px 0"}}>
        <strong>Experience and scope: </strong>Up to six group coaching sessions
        taking place approximately monthly during a programme. A group size of
        5-8 participants is perfect so that they get to know each other well and
        gain from others in the group as well as the external perspective of the
        coach. Our experience shows that this is a very powerful way of changing
        mindsets and behaviour. Often the relationships formed last long after
        the programme and both the firm and the individuals in the group benefit
        from these relationships and the learning.
      </p>

      <p>
        <strong> Themes: </strong> The following themes have emerged as
        recurring topics on the coaching programme:
      </p>

      <ul className="dashed nobold" style={{marginBottom: "115px"}}>
        <li>
          Understanding the politics in a professional service firm so that they
          can engage effectively and efficiently.
        </li>
        <li>
          Mapping their internal and external network so that they can be
          strategic about the networking they do.
        </li>
        <li>
          Working more broadly on BD so that they are thinking commercially
          about their contribution to the firm.
        </li>
        <li>Helping to improve confidence and combat any imposter syndrome.</li>
        <li>
          Thinking strategically about impression management, who needs to know
          the current truth about what they are doing at work and how can they
          give that information in a way that is authentic so that it becomes a
          habit and not a chore. The aim is to ensure that those who are making
          decisions about the coachee have all the information they need to make
          good decisions.
        </li>
        <li>Thinking commercially.</li>

        <li>
          Delegation and motivation of teams and individuals working for them.
        </li>
        <li>Managing upwards.</li>
        <li>Working smarter not harder, and resilience.</li>
      </ul>
    </Layout>
  );
};
export default CaseStudy;
